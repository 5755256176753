import styled from "styled-components";
import Theme from "../Theme";
import { Link } from "react-router";
import UserEvent from "../analytics/UserEvent";

function Footer() {
  return (
    <FooterContainer>
      <AddressContainer>
        <Header>Ellas Kreativ Studio</Header>
        <Line>Ella Fibicher</Line>
        <Line>Kleiststr. 18</Line>
        <Line>47546 Kalkar</Line>
        <LinkLine
          href="tel:+491786114500"
          onClick={() => UserEvent("Anruf klick in der Fusszeile")}
        >
          Tel: 0178 6114500
        </LinkLine>
        <LinkLine
          href="mailto:Ella_Fibicher@web.de"
          onClick={() => UserEvent("E-Mail klick in der Fusszeile")}
        >
          E-Mail: Ella_Fibicher@web.de
        </LinkLine>
      </AddressContainer>
      <OpeningContainer>
        <Header>Öffnungszeiten</Header>
        <Line>nach Vereinbarung</Line>
      </OpeningContainer>
      <StyledLink to="/datenschutz">Datenschutz</StyledLink>
      <StyledLink to="/impressum">Impressum</StyledLink>
    </FooterContainer>
  );
}

export default Footer;

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 2rem;
  align-items: flex-start;
  color: ${Theme.colors.light};
  background-color: rgba(0, 0, 0, 0.9);

  & > :not(:last-child) {
    margin-right: 2rem;
  }

  @media screen and (max-width: 450px) {
    flex-direction: column;

    & > :not(:last-child) {
      margin-right: 0rem;
      margin-bottom: 2rem;
    }

    padding: 4rem 1rem;
  }
`;

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const OpeningContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.h1`
  font-size: 1rem;
  margin-bottom: 1.5rem;
  font-weight: bolder;
  color: ${Theme.colors.light};
  opacity: 0.9;
`;

const Line = styled.div`
  font-size: 0.85rem;
  line-height: 1.5rem;
  color: ${Theme.colors.light};
  opacity: 0.8;
`;

const StyledLink = styled(Link)`
  font-size: 0.85rem;
  line-height: 1.5rem;
  color: ${Theme.colors.light};
  opacity: 0.8;
  text-decoration: underline dotted;
  align-self: center;

  @media screen and (max-width: 450px) {
    align-self: flex-start;
  }
`;

const LinkLine = styled.a`
  font-size: 0.85rem;
  line-height: 1.5rem;
  text-decoration: none;
  cursor: pointer;
  color: ${Theme.colors.light};
  opacity: 0.8;
`;
