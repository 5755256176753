import { useEffect } from "react";
import ImprintComponent from "./../components/Imprint";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ReactGA from "react-ga4";

function Imprint() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    if (window.localStorage?.getItem?.("cookie-consent-accepted") === "true") {
      ReactGA.send("pageview");
    }
  });

  return (
    <>
      <Header />
      <ImprintComponent />
      <Footer />
    </>
  );
}

export default Imprint;
