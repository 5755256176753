import styled from "styled-components";
import Theme from "../Theme";
import { ReactComponent as CreativeShape } from "../assets/creative-shape.svg";
import { ReactComponent as CreativeShapeDots } from "../assets/creative-shape-dots.svg";
import { ReactComponent as FlowerShape } from "../assets/flower-shape.svg";

function Story() {
  return (
    <StorySection>
      <StoryContainer>
        <Header>Sticken - Stich für Stich zum Glück</Header>
        <Description>
          Vom Kreuzstich bis zum Spannstich, von nostalgisch bis modern, vom
          Tischläufer bis zum Kräutersäckchen: Sticken ist eine äußerst
          vielseitige Handarbeitstechnik und macht großen Spaß. Wenn Sie
          Geschenke für Kinder möchten, haben Sie die Wahl zwischen
          Babylätzchen, Stickbildern zur Geburt, Messlatten, Badeponchos,
          Adventskalendern und vielem mehr!
        </Description>
        <Grid>
          <div>✓ individuell</div>
          <div>✓ handgefertigt</div>
          <div>✓ hochwertig</div>
          <div>✓ preiswert</div>
        </Grid>
        <PositionedFlowerShape />
      </StoryContainer>
      <PositionedCreativeShape />
      <PositionedCreativeShapeDots />
    </StorySection>
  );
}

export default Story;

const StorySection = styled.div`
  position: relative;
`;

const StoryContainer = styled.div`
  position: relative;
  margin-left: 40%;
  border-top-left-radius: 6rem;
  border-bottom-left-radius: 6rem;
  background-color: ${Theme.colors.tertiary};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: right;
  padding: 5rem 4rem 5rem 6rem;
  @media screen and (max-width: 800px) {
    padding: 6rem 2rem 6rem 2rem;
  }

  & > :not(:last-child) {
    margin-bottom: 4rem;
  }

  @media screen and (max-width: 800px) {
    margin-left: 0%;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  font-weight: bolder;
`;

const Header = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
`;

const Description = styled.div`
  color: ${Theme.colors.dark};
  font-size: 0.85rem;
  line-height: 2rem;
`;

const PositionedFlowerShape = styled(FlowerShape)`
  position: absolute;
  width: 4rem;
  left: -2rem;
  bottom: -2rem;
`;

const PositionedCreativeShape = styled(CreativeShape)`
  position: absolute;
  width: 50rem;
  left: -2rem;
  top: 0;
  z-index: -1;

  @media screen and (max-width: 800px) {
    visibility: hidden;
    display: none;
  }
`;

const PositionedCreativeShapeDots = styled(CreativeShapeDots)`
  position: absolute;
  width: 4rem;
  left: 20rem;
  bottom: 10rem;
  z-index: -1;

  @media screen and (max-width: 800px) {
    visibility: hidden;
    display: none;
  }
`;
