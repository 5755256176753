import { useEffect } from "react";
import ReactGA from "react-ga4";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Services from "../components/Services";
import Footer from "../components/Footer";
import CookieConsent from "../components/CookieConsent";
import styled from "styled-components";
import Story from "../components/Story";
import { ReactComponent as BackgroundLeftShape1 } from "../assets/background-left-shape1.svg";
import { ReactComponent as BackgroundLeftShape2 } from "../assets/background-left-shape2.svg";
import { ReactComponent as BackgroundLeftShape3 } from "../assets/background-left-shape3.svg";
import { ReactComponent as BackgroundLeftShape4 } from "../assets/background-left-shape4.svg";
import { ReactComponent as BackgroundRightShape1 } from "../assets/background-right-shape1.svg";
import { ReactComponent as BackgroundRightShape2 } from "../assets/background-right-shape2.svg";

function Home() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    if (window.localStorage?.getItem?.("cookie-consent-accepted") === "true") {
      ReactGA.send("pageview");
    }
  }, []);

  return (
    <>
      <Header />
      <Main>
        <PositionedBackgroundLeftShape1 />
        <PositionedBackgroundLeftShape2 />
        <PositionedBackgroundLeftShape3 />
        <PositionedBackgroundLeftShape4 />

        <PositionedBackgroundRightShape1 />
        <PositionedBackgroundRightShape2 />

        <Hero />
        <Services />
        <Story />
      </Main>
      <Footer />
      <CookieConsent />
    </>
  );
}

export default Home;

const Main = styled.main`
  position: relative;
  overflow: hidden;
  width: 100%;

  scroll-snap-type: y mandatory;

  & > div {
    padding-bottom: 15rem;
    scroll-snap-align: start;
  }
`;

const PositionedBackgroundLeftShape1 = styled(BackgroundLeftShape1)`
  position: absolute;
  width: 15rem;
  top: 0rem;
  left: -10rem;
  z-index: -1;

  @media screen and (max-width: 800px) {
    width: 10rem;
    top: -2rem;
    left: -7rem;
  }
`;

const PositionedBackgroundLeftShape2 = styled(BackgroundLeftShape2)`
  position: absolute;
  width: 15rem;
  top: 28%;
  left: -8rem;
  z-index: -1;

  @media screen and (max-width: 800px) {
    width: 10rem;
    left: -7rem;
  }
`;

const PositionedBackgroundLeftShape3 = styled(BackgroundLeftShape3)`
  position: absolute;
  width: 13rem;
  top: 50%;
  left: -9rem;
  z-index: -1;

  @media screen and (max-width: 800px) {
    top: 48%;
    width: 10rem;
    left: -7rem;
  }
`;

const PositionedBackgroundLeftShape4 = styled(BackgroundLeftShape4)`
  position: absolute;
  width: 4rem;
  top: 93%;
  left: -1rem;
  z-index: -1;

  @media screen and (max-width: 800px) {
    top: 95%;
    width: 4rem;
    left: -1rem;
  }
`;

const PositionedBackgroundRightShape1 = styled(BackgroundRightShape1)`
  position: absolute;
  width: 12rem;
  top: 33%;
  right: -8rem;
  z-index: -1;

  @media screen and (max-width: 800px) {
    top: 36%;
    width: 10rem;
    right: -6rem;
  }
`;

const PositionedBackgroundRightShape2 = styled(BackgroundRightShape2)`
  position: absolute;
  width: 16rem;
  top: 52%;
  right: -7rem;
  z-index: -1;

  @media screen and (max-width: 800px) {
    top: 60%;
    width: 10rem;
    right: -6rem;
  }
`;
