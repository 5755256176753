import { useEffect } from "react";
import DataProtectionComponent from "./../components/DataProtection";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ReactGA from "react-ga4";

function DataProtection() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    if (window.localStorage?.getItem?.("cookie-consent-accepted") === "true") {
      ReactGA.send("pageview");
    }
  });

  return (
    <>
      <Header />
      <DataProtectionComponent />
      <Footer />
    </>
  );
}

export default DataProtection;
