import { useState } from "react";
import IconFacebook from "./svg/IconFacebook";
import IconInstagram from "./svg/IconInstagram";
import IconTwitter from "./svg/IconTwitter";
import IconYoutube from "./svg/IconYoutube";
import styled, { css } from "styled-components";
import Theme from "../Theme";
import { Navigate } from "react-router";
import UserEvent from "../analytics/UserEvent";
import { darken } from "polished";

function Header() {
  const [toHome, setToHome] = useState<boolean>(false);

  return (
    <Container>
      <Logo onClick={(e) => setToHome(true)}>Ellas Kreativ Studio</Logo>
      {toHome && <Navigate to="/" />}
      <Nav>
        <NavLink
          $hidden={true}
          href="https://facebook.com/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => UserEvent("Facebook klick in der Navigationsleiste")}
        >
          <StyledIconFacebook />
        </NavLink>
        <NavLink
          $hidden={false}
          href="https://www.instagram.com/ellaskreativstudio/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => UserEvent("Instagram klick in der Navigationsleiste")}
        >
          <StyledIconInstagram />
        </NavLink>
        <NavLink
          $hidden={true}
          href="https://www.twitter.de/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => UserEvent("Twitter klick in der Navigationsleiste")}
        >
          <StyledIconTwitter />
        </NavLink>
        <NavLink
          $hidden={true}
          href="https://www.youtube.de/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => UserEvent("Youtube klick in der Navigationsleiste")}
        >
          <StyledIconYoutube />
        </NavLink>
        <NavButton
          href="tel:+491786114500"
          onClick={() => UserEvent("Anrufen klick in der Navigationsleiste")}
        >
          Anrufen
        </NavButton>
      </Nav>
    </Container>
  );
}

export default Header;

const Container = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  box-sizing: border-box;
  color: ${Theme.colors.light};
  background-color: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(10px);
  opacity: 0.98;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  z-index: 5;

  @media screen and (max-width: 450px) {
    padding: 1rem 1rem;
  }
`;

const BaseStyledIcon = css`
  height: 1.1rem;
  padding-right: 0.7rem;
  flex-shrink: 0;
  cursor: pointer;
  color: ${Theme.colors.primary};
  transition: color 0.3s ease-in-out;

  @media screen and (max-width: 450px) {
    font-size: 0.9rem;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: ${(p) => darken(0.1, Theme.colors.primary)};
    }
  }
`;

const StyledIconFacebook = styled(IconFacebook)`
  ${BaseStyledIcon}
`;

const StyledIconInstagram = styled(IconInstagram)`
  ${BaseStyledIcon}
`;

const StyledIconTwitter = styled(IconTwitter)`
  ${BaseStyledIcon}
`;

const StyledIconYoutube = styled(IconYoutube)`
  ${BaseStyledIcon}
`;

const Logo = styled.div`
  flex-shrink: 0;
  padding-right: 2rem;
  cursor: pointer;
  font-family: "barcelonyregular";
  font-size: 1.25rem;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: right;
`;

const NavLink = styled.a<{ $hidden: boolean }>`
  display: ${(p) => (p.$hidden ? "none" : "flex")};
  align-items: center;
  color: ${Theme.colors.light};
  text-decoration: none;
  transition: border-bottom 0.3s ease-in;
  margin-right: 2rem;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const NavButton = styled.a`
  font-size: 0.8rem;
  font-weight: bolder;
  text-transform: uppercase;
  position: relative;
  color: ${Theme.colors.primary};
  text-decoration: none;
  border: 1px solid ${Theme.colors.primary};
  padding: 0.6rem 1.7rem;
  border-radius: 1.4rem;
  transition: background-color 0.3s ease-in-out 0s, color 0.3s ease-in-out 0s,
    border-color 0.3s ease-in-out 0s;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${darken(0.1, Theme.colors.primary)};
      border-color: ${darken(0.1, Theme.colors.primary)};
      color: ${Theme.colors.dark};
    }
  }

  @media screen and (max-width: 350px) {
    display: none;
  }
`;
